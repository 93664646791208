/**
 * External Dependencies
 */
import 'bootstrap/js/dist/modal';


/**
 * Modules
 */
import './modules/hide-preloader';
import './modules/gtm-event';
import updateStyleVariables from './modules/resize-dependent';
import modalVideo from './modules/modal-video';
import modalYoutubeVideo from './modules/modal-video-youtube';
import modal from './modules/modal';
import textReadMore from './modules/text-read-more';
import customScrollbar from './modules/custom-scrollbar';
import ninjaFormsSelect from './modules/ninja-forms-select';
import customLazyLoad from './modules/custom-lazy-load';

const admin = () => {
	setTimeout( () => {
		updateStyleVariables();
	}, 2000 );
};

const front = () => {
	const $body = $( 'body' );
	$body.removeClass( 'scroll-off' );
	updateStyleVariables();
	modalVideo();
	modalYoutubeVideo();
	modal();
	textReadMore();
	customScrollbar();
	ninjaFormsSelect();
	customLazyLoad();
};

$( () => {
	const $body = $( 'body' );
	if (
		$body.hasClass( 'wp-admin' ) ||
		$body.hasClass( 'block-editor-iframe__body' )
	) {
		admin();
	} else {
		front();
	}
} );

const lazyLoadVideo = () => {
	let $video = $( '.section-hero__bg video' );
	if ( $video.length === 0 ) {
		return;
	}
	let source = $video.find( 'source' );
	let posterImage = new Image();
	posterImage.src = $video.attr( 'data-poster' );
	let dataSrc = source.attr( 'data-src' );
	source.attr( 'src', dataSrc );
	$video[0].load();
};

const lazyLoadPoster = () => {
	let $video = $( '.section-hero__bg video' );
	if ( $video.length === 0 || !$video.attr( 'data-poster' ) ) {
		return;
	}
	let posterImage = new Image();
	posterImage.src = $video.attr( 'data-poster' );
	posterImage.onload = function() {
		$video[0].poster = posterImage.src;
	};
	posterImage.src = $video.attr( 'data-poster' );
};

window.onload = function() {
	lazyLoadVideo( );
	lazyLoadPoster( );
};
